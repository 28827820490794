// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { catalogoScripts } from "../../../scripts/catalogo"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiCfdiMinimo: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Buscar claves unidad</h2>
                <p>
                El catálogo de unidades funciona muy similar a Clave de Producto o Servicio, ya que tienen el mismo propósito.
                </p>
                <pre>
                    <code>
                        <b>POST:</b> https://api.fiscalpop.com/api/v1/sat/claveUnidades/<b><span className="error">:authToken</span></b>
                    </code>
                </pre>
                <h3>
                    Campos del Request
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>compare: </b> Texto libre para comparar las entradas del catálogo de unidades contra este valor.
                        </p>
                    </li>
                </ul>
                <h3>
                    Campos de la Respuesta
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>clave: </b> La clave a usar como unidad en tu concepto.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>nombre:</b> Nombre dado a la unidad en el catálogo.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>simbolo:</b> Representación simbólica de las unidades, en UTF-8.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>complemento:</b> Se espera usar en complementos (solo sugerencia, no obligatorio).
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>palabrasSimilares:</b> Palabras similares que incluye el catálogo para esta clave.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>match:</b> Certeza de la respuesta, que tan hipotéticamente seguro es que esta clave es la deseada.
                        </p>
                    </li>
                </ul>
                <SectionNavigate next={`/api/catalogo/catalogo-impuestos`} previous={`/api/catalogo/catalogo-productoservicios`}/>
            </div>
            <div className="side">
                <CodeBlock title="Clave de Unidad" requests={catalogoScripts.unidades.requests} response={catalogoScripts.unidades.response} />
            </div>
        </div>
    </Layout>
)

export default ApiCfdiMinimo
